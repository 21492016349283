import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Partners from '../components/partners'
import Automate from '../components/automate'

import BillsbyLogo from '../images/billsby-logo-black.svg'
import Chargebee from '../images/chargebee.svg'
import Recurly from '../images/recurly.svg'
import Stripe from '../images/stripe.svg'

const partners = [
  {
    imgSrc: BillsbyLogo,
    name: 'Billsby',
    title: 'Checkout and Pages'
  },
  {
    imgSrc: Chargebee,
    name: 'Chargebee',
    title: 'Checkout and Modal'
  },
  {
    imgSrc: Recurly,
    name: 'Recurly',
    title: 'Hosted Pages',
    comingSoon: true
  },
  {
    imgSrc: Stripe,
    name: 'Stripe',
    title: 'Checkout',
    comingSoon: true
  }
]

const operations = [
  {
    number: '1',
    text: 'Data about your new, cancelled and updated subscriptions is imported from your subscription billing software'
  },
  {
    number: '2',
    text: 'GetOnboard.ly augments the data provided by your subscription software with extra fields and data'
  },
  {
    number: '3',
    text: 'GetOnboard.ly automatically creates and provisions all of the accounts and tools your subscriber needs'
  },
  {
    number: '4',
    text: 'When details change down the line (like an updated email address) GetOnboard.ly keeps everything in sync'
  },
]
const Home = () => (
  <Layout className="home" >
    <SEO title="Home" />
    <Banner
      homeBanner
      title="The no-code subscription provisioning manager you didn’t know you needed that puts your subscription business on auto pilot"
      description="Never manually provision a customer again and get notified every time customers sign up or cancel their subscription. Works with all of the most popular subscription billing platforms. Integrates with dozens of accounts and services you might need to provision for your subscription clients."
    />
    <Partners partners={partners} />
    <Automate operations={operations} />
  </Layout>
)

export default Home
