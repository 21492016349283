import React from 'react'
import clsx from 'clsx';

import ComingSoon from '../images/coming-soon@2x.png'

const Partners = ({ partners }) => {
  return (
    <div className="section-partners">
      <div className="container">
        <p>works with</p>
        <div className="logo-wrapper">
          {partners.map((partner, i) => {
            return (
              <div
                key={i}
                className={clsx('logo-holder', {
                  'coming-soon': partner.comingSoon
                })}>
                <img src={partner.imgSrc} alt={partner.name} className="logo-img" />
                <p>{partner.title}</p>
                {partner.comingSoon && (
                  <img src={ComingSoon} alt="Coming Soon" className="coming-soon-text" />
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Partners
