import React from 'react'

import AutomateImg from '../images/automate-img.svg'
import SplodeGray from '../images/splode-gray.svg'

const Automate = ({ operations }) => {
  return (
    <div className="section-automate">
      <div className="container">
        <h2 className="heading-1">Automate your subscription operations</h2>
        <p>Stop wasting time manually creating your new subscribers in third party systems or relying on convoluted chains of zaps</p>
        <div className="operation-wrapper">
          {operations.map((operation, i) => {
            return (
              <div key={i} className="operation-holder">
                <h2>{operation.number}</h2>
                <p>{operation.text}</p>
              </div>
            )
          })}
        </div>
        <div className="image-wrapper">
          <img src={SplodeGray} alt="Splode Gray" className="splode-gray" />
          <img src={AutomateImg} alt="Automate" />
        </div>
      </div>
    </div>
  )
}

export default Automate
